/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import "fontsource-kanit/200.css";
import "fontsource-kanit/800.css";

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    background: #41c2f3;

    @media screen and (max-width: 896px) and (orientation: landscape) {
      background: #000;
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Layout.Main>{children}</Layout.Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.Main = styled.main`
  height: 100%;

  @media screen and (max-width: 896px) and (orientation: landscape) {
    background: #41c2f3;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
`;

export default Layout
